// 转文件格式

function format(file,msg) {
	let mime = null
		let bstr = null
		let n = null
		let u8arr = null
	var arr = file.content.split(',')
	mime = arr[0].match(/:(.*?);/)[1]
	bstr = atob(arr[1])
	n = bstr.length
	u8arr = new Uint8Array(n)
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n)
	}
	msg = new File([u8arr], file.file.name, {
		type: mime
	});
}
export default format