<template>
  <div class="eyePhysician">
    <div class="content">
      <van-field
        v-model="messageContent"
        rows="5"
        autosize
        type="textarea"
        maxlength="500"
        placeholder="为了更好的获得医生帮助，请尽可能详细描述病情"
        show-word-limit
      />
      <span class="num">{{ fileList.length }}/9</span>
    </div>
    <vaeTitle title="上传检查报告或患处照片"></vaeTitle>
    <div class="tips flex">
      <span>照片仅自己和医生可见</span>
      <span>已上传</span>
    </div>

    <div class="upload">
      <van-uploader
        v-model="fileList"
        :after-read="afterRead"
        multiple
        :max-count="9"
      />
    </div>
    <div class="footer">
      <van-button round type="info" block @click="consulting">咨询</van-button>
    </div>
  </div>
</template>

<script>
import vaeTitle from '@/components/title.vue'
import format from '@/utils/format.js'
import { qiniuUploadImage, consultationSave } from '@/api/studentProduct'
export default {
  components: {
    vaeTitle
  },
  data () {
    return {
      messageContent: '',
      num: 0,
      fileList: [],
      qiniuData: {
        domain: '',
        key: '',
        token: ''
      },
      qiniuUploadUrl: 'https://up-z2.qiniup.com',
      imgListUrl: []
    }
  },
  created () {},
  methods: {
    async consulting () {
      if (this.messageContent == '') {
        this.$toast({
          message: '请输入内容',
          type: 'error'
        })
      } else {
        const obj = {
          content: this.messageContent,
          img: this.imgListUrl.join(',')
        }
        const res = await consultationSave(obj)
        if (res.data.code == 200) {
          this.$router.push({
            path: '/customer',
            query: {
              id: parseInt(res.data.data)
            }
          })
        }
      }
    },
    async afterRead (file) {
      const res = await qiniuUploadImage()
      if (res.data.code == 200) {
        const data = new FormData()
        data.append('token', res.data.data.token)
        data.append('key', res.data.data.oss_file_name)
        data.append('domain', res.data.data.domain)
        data.append('file', file.file)
        this.$request
          .post(this.qiniuUploadUrl, data, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(res1 => {
            console.log(res1);
            if (res1.status == 200) {
              this.$toast({
                message: '上传成功',
                type: 'success'
              })
            }
            const imgUrl = res.data.data.domain + res1.data.key
            // console.log(imgUrl);
            this.imgListUrl.push(imgUrl)
            // console.log(msg);
          })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.eyePhysician {
  position: relative;
  .content {
    position: relative;
    padding: 15px;
    .num {
      position: absolute;
      right: 15px;
      bottom: -24px;
      color: #999999;
      font-size: 14px;
    }
  }
  .upload {
    padding: 0 15px;
  }
  .footer {
    width: 40%;
    position: absolute;
    bottom: -270px;
    left: 50%;
    transform: translateX(-50%);
  }
  .tips {
    padding: 10px 15px;
    color: #999999;
    font-size: 14px;
  }

  .van-field__label {
    display: none;
  }
  .van-cell__title,
  .van-cell__value {
    display: none;
  }
  .van-cell {
    border-radius: 10px;
    box-shadow: 1px 1px 10px rgba(0, 96, 240, 0.1);
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  ::v-deep .van-uploader__upload {
    background-color: #fff !important;
  }
  ::v-deep .van-uploader__preview-image {
    border: 1px solid #ccc;
  }
  ::v-deep .van-uploader__preview {
    margin-right: 30px;
  }
}
</style>
